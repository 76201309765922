import React, { useEffect } from "react";
import { Form, Input, InputNumber, Switch, Select } from "antd";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const defaultValues = {
  name: "",
  price: 0,
  hours_of_training: 0,
  isService: false,
  main_service: false,
  una_tantum: false,
  power_by: "",
  power_by_link: "",
  order: 0,
  module_category_pointer: undefined,
  quantity: false,
  included_quantity: 0,
  charge_by_quantity: false,
  price_for_quantity: 0,
  sku: "",
  is_active: true
};

const ModuleEditForm = ({ form, initialValues, isCreating, categories }) => {
  useEffect(() => {
    form.setFieldsValue({
      name: initialValues.name || defaultValues.name,
      price: initialValues.price || defaultValues.price,
      hours_of_training: initialValues.hours_of_training || defaultValues.hours_of_training,
      isService: initialValues.isService || defaultValues.isService,
      main_service: initialValues.main_service || defaultValues.main_service,
      una_tantum: initialValues.una_tantum || defaultValues.una_tantum,
      power_by: initialValues.power_by || defaultValues.power_by,
      power_by_link: initialValues.power_by_link || defaultValues.power_by_link,
      order: initialValues.order || defaultValues.order,
      module_category_pointer: initialValues.module_category_pointer?.objectId || defaultValues.module_category_pointer,
      quantity: initialValues.quantity || defaultValues.quantity,
      included_quantity: initialValues.included_quantity || defaultValues.included_quantity,
      charge_by_quantity: initialValues.charge_by_quantity || defaultValues.charge_by_quantity,
      price_for_quantity: initialValues.price_for_quantity || defaultValues.price_for_quantity,
      sku: initialValues.sku || defaultValues.sku,
      is_active: initialValues.is_active !== undefined ? initialValues.is_active : defaultValues.is_active
    });
  }, [form, initialValues]);

  return (
    <Form
      {...layout}
      form={form}
      name="module_edit"
    >
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true, message: "Inserisci il nome del modulo!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="sku"
        label="SKU"
        rules={[{ required: true, message: "Inserisci lo SKU del modulo!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="is_active"
        label="Attivo"
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>

      <Form.Item
        name="price"
        label="Prezzo"
        rules={[{ required: true, message: "Inserisci il prezzo!" }]}
      >
        <InputNumber
          min={0}
          step={0.01}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="quantity"
        label="Quantità"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="included_quantity"
        label="Quantità Inclusa"
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="charge_by_quantity"
        label="Addebito per Quantità"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="price_for_quantity"
        label="Prezzo per Quantità"
      >
        <InputNumber
          min={0}
          step={0.01}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="hours_of_training"
        label="Minuti di Formazione"
        rules={[{ required: true, message: "Inserisci i minuti di formazione!" }]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="isService"
        label="È un Servizio"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="main_service"
        label="Aquistabile Singolarmente"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="una_tantum"
        label="Una Tantum"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="power_by"
        label="Powered By"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="power_by_link"
        label="Powered By Link"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="order"
        label="Ordine"
        rules={[{ required: true, message: "Inserisci l'ordine!" }]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="module_category_pointer"
        label="Categoria"
      >
        <Select
          allowClear
          placeholder="Seleziona una categoria"
          optionFilterProp="children"
          showSearch
        >
          {categories.map(category => (
            <Select.Option key={category.objectId} value={category.objectId}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ModuleEditForm; 