import React, { useState } from "react";
import {
  Affix,
  Layout,
  Typography,
  Menu,
  Switch,
  Space,
  Divider,
  Button,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../react-auth0-spa";
import { sidebarItems } from "../../config";

const { Title, Text } = Typography;
const { Sider } = Layout;
const { SubMenu } = Menu;

function LogoutButton() {
  const { logout } = useAuth0();

  const logoutCss = {
    position: "absolute",
    top: "90vh",
    left: "50%",
    marginLeft: "-12px",
  };

  return (
    <Button
      size="small"
      icon={<LogoutOutlined />}
      onClick={() => logout({ returnTo: window.location.origin })}
      style={logoutCss}
    ></Button>
  );
}

function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);
  const { isProd, toggleEnv } = useAppContext();

  return (
    <Affix offsetTop={0}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{ height: "100vh" }}
      >
        <Space align="center">
          <img
            src="https://cdn.onoranzefunebricloud.com/logos/singolo_white.png"
            width="61"
            height="40"
            alt="logo"
            style={{
              marginTop: "8px",
              marginBottom: "8px",
              marginLeft: "8px",
              marginRight: collapsed ? "8px" : "0",
            }}
          />
          {!collapsed && (
            <Title
              level={2}
              style={{
                color: "rgba(255, 255, 255)",
                lineHeight: "1em",
                margin: "8px 0",
              }}
            >
              Mission Control
            </Title>
          )}
        </Space>
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {sidebarItems.map((sidebarItem) => {
            if (sidebarItem.items) {
              return (
                <SubMenu
                  title={sidebarItem.title}
                  icon={sidebarItem.icon}
                  key={`submenu-${sidebarItem.title}`}
                >
                  {sidebarItem.items.map((sidebarItem) => {
                    if (sidebarItem.items) {
                      return (
                        <SubMenu
                          title={sidebarItem.title}
                          icon={sidebarItem.icon}
                          key={`sub-submenu-${sidebarItem.title}`}
                        >
                          {sidebarItem.items.map((sidebarItem) => {
                            return (
                              <Menu.Item key={sidebarItem.title}>
                                <Link to={sidebarItem.url}>
                                  {sidebarItem.title}
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }

                    if (!sidebarItem.items) {
                      return (
                        <Menu.Item
                          key={sidebarItem.title}
                          icon={sidebarItem.icon}
                        >
                          <Link to={sidebarItem.url}>{sidebarItem.title}</Link>
                        </Menu.Item>
                      );
                    }

                    return <></>;
                  })}
                </SubMenu>
              );
            }

            if (!sidebarItem.items) {
              return (
                <Menu.Item key={sidebarItem.title} icon={sidebarItem.icon}>
                  <Link to={sidebarItem.url}>{sidebarItem.title}</Link>
                </Menu.Item>
              );
            }

            return <></>;
          })}
        </Menu>

        <Divider />

        <div style={{ textAlign: "center" }}>
          <Space direction="vertical">
            <Text
              type="secondary"
              style={{ color: "rgba(255, 255, 255, 0.65)" }}
              level={4}
            >
              {collapsed ? "Env" : "Environment"}
            </Text>
            <Switch
              defaultChecked={isProd}
              checkedChildren={collapsed ? "P" : "Production"}
              unCheckedChildren={collapsed ? "D" : "Development"}
              onChange={toggleEnv}
            />
          </Space>
        </div>
        <LogoutButton />
      </Sider>
    </Affix>
  );
}

export default Sidebar;
