import React, { useState, useCallback, useEffect } from "react";
import { Form, Modal, Spin, message } from "antd";
import ModuleEditForm from "./ModuleEditForm";
import axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const updateModuleMutation = `
  mutation UpdateModule($id: ID!, $fields: UpdateModuleFieldsInput) {
    updateModule(input: { id: $id, fields: $fields }) {
      module {
        objectId
      }
    }
  }
`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

const createModuleMutation = `
  mutation CreateModule($fields: CreateModuleFieldsInput) {
    createModule(input: { fields: $fields }) {
      module {
        objectId
      }
    }
  }
`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

const findModuleCategoriesQuery = `
  query findModuleCategories {
    module_categories(first: 10000) {
      edges {
        node {
          objectId
          name
          color
          order
        }
      }
    }
  }
`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

const ModuleEditModal = ({ visible, module, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const isCreating = !module;

  const fetchCategories = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findModuleCategoriesQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('response.data', response.data);
      if (response.data) {
        const categoriesData = response.data
          .sort((a, b) => (a.order || 0) - (b.order || 0));
        setCategories(categoriesData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error("Failed to load categories");
    }
  }, [env, getTokenSilently]);

  useEffect(() => {
    if (visible) {
      fetchCategories();
    }
  }, [visible, fetchCategories]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const token = await getTokenSilently();
      
      const fields = {
        name: values.name,
        price: parseFloat(values.price),
        hours_of_training: parseInt(values.hours_of_training),
        isService: values.isService,
        main_service: values.main_service,
        una_tantum: values.una_tantum,
        power_by: values.power_by,
        power_by_link: values.power_by_link,
        order: parseInt(values.order),
        sku: values.sku,
        quantity: Boolean(values.quantity),
        included_quantity: values.included_quantity ? values.included_quantity.toString() : "0",
        charge_by_quantity: values.charge_by_quantity || false,
        price_for_quantity: values.price_for_quantity ? parseFloat(values.price_for_quantity) : 0,
        is_active: values.is_active,
        module_category_pointer: values.module_category_pointer ? {
          link: values.module_category_pointer
        } : null
      };

      const response = isCreating ? 
        await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: createModuleMutation,
            variables: { fields },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }) :
        await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: updateModuleMutation,
            variables: { id: module.objectId, fields },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

      if (response.data.errors) {
        const errorMessages = response.data.errors.map(error => error.message).join(', ');
        throw new Error(errorMessages);
      }

      message.success(`Modulo ${isCreating ? 'creato' : 'aggiornato'} con successo`);
      onSuccess();
    } catch (error) {
      console.error("Errore nel salvare il modulo:", error);
      message.error(error.message || `Impossibile ${isCreating ? 'creare' : 'aggiornare'} il modulo`, 10);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={isCreating ? "Nuovo Modulo" : "Modifica Modulo"}
      okText={isCreating ? "Crea" : "Salva"}
      cancelText="Annulla"
      onCancel={onCancel}
      width={800}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && (
        <ModuleEditForm
          form={form}
          initialValues={module || {}}
          isCreating={isCreating}
          categories={categories}
        />
      )}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default ModuleEditModal; 