import React from "react";

// Logos
import AllureLogo from "./images/logos/allure.png";
import AnnunciFunebriLogo from "./images/logos/annuncifunebri.png";
import ArubaFatturaElettronicaLogo from "./images/logos/aruba_fattura_elettronica.png";
import ArubaPartnerLogo from "./images/logos/aruba_partner.png";
import Auth0Logo from "./images/logos/auth0.png";
import AwsLogo from "./images/logos/aws.png";
import BlSrlLogo from "./images/logos/blsrl.png";
import CanvaLogo from "./images/logos/canva.png";
import CardsLogo from "./images/logos/cards.png";
import DataStudioLogo from "./images/logos/datastudio.png";
import DockerHubLogo from "./images/logos/dockerhub.png";
import ElasticLogo from "./images/logos/elasticsearch.svg";
import EnvatoLogo from "./images/logos/envato.jpg";
import F6sLogo from "./images/logos/f6s.png";
import FacebookLogo from "./images/logos/facebook.png";
import FaunaDbLogo from "./images/logos/faunadb.png";
import FlatIconLogo from "./images/logos/flaticon.png";
import FluidaLogo from "./images/logos/fluida.jpg";
import GanLogo from "./images/logos/gan.png";
import GitHubLogo from "./images/logos/github.png";
import GitpodLogo from "./images/logos/gitpod.jpg";
import GoogleAdwordsLogo from "./images/logos/google_adwords.png";
import GoogleAnalyticsLogo from "./images/logos/google_analytics.png";
import GooglePostmastersLogo from "./images/logos/google_postmasters.png";
import GoogleSearchConsoleLogo from "./images/logos/google_search_console.jpg";
import GoogleTagManagerLogo from "./images/logos/google_tag_manager.png";
import GoogleTrendsLogo from "./images/logos/google_trends.png";
import GrafanaLogo from "./images/logos/grafana.jpg";
import GraphQlLogo from "./images/logos/graphql.png";
import GSuiteLogo from "./images/logos/g_suite.png";
import HubspotLogo from "./images/logos/hubspot.png";
import InstagramLogo from "./images/logos/instagram.jpg";
import IntercomLogo from "./images/logos/intercom.png";
import IubendaLogo from "./images/logos/iubenda.png";
import JiraLogo from "./images/logos/jira.png";
import KeliwebLogo from "./images/logos/keliweb.png";
import LinkedInLogo from "./images/logos/linkedin.png";
import LoomLogo from "./images/logos/loom.png";
import LyberLogo from "./images/logos/lyber.jpg";
import MailTesterLogo from "./images/logos/mail_tester.png";
import MongoDbAtlasLogo from "./images/logos/mongodb.png";
import NotionLogo from "./images/logos/notion.png";
import NpmLogo from "./images/logos/npm.svg";
import OfcSupportLogo from "./images/logos/ofc_support.png";
import OneSignalLogo from "./images/logos/onesignal.png";
import OpenCollectiveLogo from "./images/logos/open_collective.png";
import OpsGenieLogo from "./images/logos/opsgenie.png";
import ParseLogo from "./images/logos/parse.png";
import PatreonLogo from "./images/logos/patreon.png";
import PhpLogo from "./images/logos/php.png";
import SketchLogo from "./images/logos/sketch.png";
import StackShareLogo from "./images/logos/stackshare.png";
import StripeLogo from "./images/logos/stripe.png";
import TailwindLogo from "./images/logos/tailwind.svg";
import UnsplashLogo from "./images/logos/unsplash.png";
import UpscopeLogo from "./images/logos/upscope.png";
import VastLogo from "./images/logos/vast.png";
import WPLogo from "./images/logos/wp.png";
import YouTubeLogo from "./images/logos/youtube.png";
import ZohoAnalytics from "./images/logos/zoho_analytics.png";
import MixPanel from "./images/logos/mixpanel.png";
import WebflowLogo from "./images/logos/webflow.png";

// Menu icons
import {
  AppstoreOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  IeOutlined,
  GoogleOutlined,
  LineChartOutlined,
  UserOutlined,
  ApartmentOutlined,
  SettingOutlined,
  ToolOutlined
} from "@ant-design/icons";

const OfcLogo = "https://cdn.onoranzefunebricloud.com/logos/ofc_blu.png";
const GiottoLogo = "https://cdn.onoranzefunebricloud.com/logos/giotto_blu.png";
const EternoLogo = "https://cdn.onoranzefunebricloud.com/logos/eterno_blu.png";

export const appsConfig = [
  /**
   * Left Column
   */
  {
    title: "Data & Cloud Providers",
    displayOnSide: "left",
    apps: [
      {
        name: "Parse Dashboard",
        url: "http://parse-dashboard.dev-onoranzefunebricloud.com/",
        logo: ParseLogo,
        env: "dev",
      },
      {
        name: "Parse Dashboard",
        url: "http://parse-dashboard.onoranzefunebricloud.com/",
        logo: ParseLogo,
        env: "prod",
      },
      {
        name: "AWS Dev",
        url: "https://482321971896.signin.aws.amazon.com/console",
        logo: AwsLogo,
        env: "dev",
      },
      {
        name: "AWS Prod",
        url: "https://118905968587.signin.aws.amazon.com/console",
        logo: AwsLogo,
        env: "prod",
      },
      {
        name: "AWS Backup",
        url: "https://730335481505.signin.aws.amazon.com/console",
        logo: AwsLogo,
        env: "prod",
      },
      {
        name: "MongoDB Atlas",
        url: "https://cloud.mongodb.com/",
        logo: MongoDbAtlasLogo,
      },
      {
        name: "Elasticsearch",
        url: "https://011ea75f19904d5a8dd348ce4c44253c.us-east-1.aws.found.io:9243/login?next=%2F",
        logo: ElasticLogo,
      },
      {
        name: "Keliweb",
        url: "https://www.keliweb.it/",
        logo: KeliwebLogo,
      },
      {
        name: "Vast.ai",
        url: "https://cloud.vast.ai/",
        logo: VastLogo,
      },
      {
        name: "DockerHub",
        url: "https://hub.docker.com/u/onoranzefunebricloud",
        logo: DockerHubLogo,
      },
      {
        name: "FaunaDB",
        url: "https://dashboard.fauna.com",
        logo: FaunaDbLogo,
      },
    ],
  },
  {
    title: "Monitoring",
    displayOnSide: "left",
    apps: [
      {
        name: "Grafana",
        url: "https://grafana.dev-onoranzefunebricloud.com/",
        logo: GrafanaLogo,
        env: "dev",
      },
      {
        name: "Grafana",
        url: "https://grafana.onoranzefunebricloud.com/",
        logo: GrafanaLogo,
        env: "prod",
      },
      {
        name: "OpsGenie",
        url: "https://onoranzefunebricloud.app.eu.opsgenie.com/",
        logo: OpsGenieLogo,
      },
    ],
  },
  {
    title: "Development",
    displayOnSide: "left",
    apps: [
      {
        name: "GitHub",
        url: "https://github.com/onoranzefunebricloud",
        logo: GitHubLogo,
      },
      {
        name: "Gitpod",
        url: "https://gitpod.io/t/ofc/projects",
        logo: GitpodLogo,
      },
      {
        name: "OFC NPM Registry",
        url: "https://www.npmjs.com/settings/onoranzefunebricloud/packages",
        logo: NpmLogo,
      },
      {
        name: "Allure (dev)",
        url: "https://test-reports.dev-onoranzefunebricloud.com",
        logo: AllureLogo,
        env: "dev",
      },
      {
        name: "Allure (prod)",
        url: "https://test-reports.onoranzefunebricloud.com",
        logo: AllureLogo,
        env: "prod",
      },
      {
        name: "OneSignal (dev)",
        url: "https://app.onesignal.com/apps/f064c61a-609d-409c-88f2-cac4a80a88e9",
        logo: OneSignalLogo,
        env: "dev",
      },
      {
        name: "OneSignal (prod)",
        url: "https://app.onesignal.com/apps/51b8f54e-4b35-4856-a401-9aac18e791b7",
        logo: OneSignalLogo,
        env: "prod",
      },
    ],
  },
  {
    title: "Products",
    displayOnSide: "left",
    apps: [
      {
        name: "Eterno",
        url: "https://eterno.dev-onoranzefunebricloud.com",
        logo: EternoLogo,
        env: "dev",
      },
      {
        name: "Eterno",
        url: "https://eterno.onoranzefunebricloud.com",
        logo: EternoLogo,
        env: "prod",
      },
      {
        name: "Giotto",
        url: "https://editor.giotto.dev-onoranzefunebricloud.com",
        logo: GiottoLogo,
        env: "dev",
      },
      {
        name: "Giotto",
        url: "https://editor.giotto.onoranzefunebricloud.com",
        logo: GiottoLogo,
        env: "prod",
      },
      {
        name: "Cards Dashboard",
        url: "https://cards-admin.dev-onoranzefunebricloud.com",
        logo: CardsLogo,
        env: "dev",
      },
      {
        name: "Cards Dashboard",
        url: "https://cards-admin.onoranzefunebricloud.com",
        logo: CardsLogo,
        env: "prod",
      },
      {
        name: 'Marketplace',
        url: 'https://shop.dev-onoranzefunebricloud.com',
        logo: EternoLogo,
        env: 'dev'
      },
      {
        name: 'Marketplace',
        url: 'https://onoranzefunebrishop.it',
        logo: EternoLogo,
        env: 'prod'
      }
    ],
  },
  {
    title: "Landing Pages",
    displayOnSide: "left",
    apps: [
      {
        name: "WordPress Admin",
        url: "https://onoranzefunebricloud.com/wp-admin",
        logo: WPLogo,
      },
      {
        name: "OFC",
        url: "https://onoranzefunebricloud.com",
        logo: OfcLogo,
      },
    ],
  },
  {
    title: "Software Docs",
    displayOnSide: "left",
    apps: [
      {
        name: "Tailwind CSS",
        url: "https://tailwindcss.com/docs",
        logo: TailwindLogo,
      },
      {
        name: "Parse Server JS SDK",
        url: "https://docs.parseplatform.org/js/guide/",
        logo: ParseLogo,
      },
      {
        name: "Parse Server GraphQL",
        url: "https://docs.parseplatform.org/graphql/guide/",
        logo: GraphQlLogo,
      },
      {
        name: "Parse Server PHP SDK",
        url: "https://docs.parseplatform.org/php/guide/",
        logo: PhpLogo,
      },
      {
        name: "Parse Env Vars Config",
        url: "https://github.com/parse-community/parse-server/blob/alpha/src/Options/Definitions.js",
        logo: GitHubLogo,
      },
    ],
  },
  {
    title: "Open Source",
    displayOnSide: "left",
    apps: [
      {
        name: "Parse Open Collective",
        url: "https://opencollective.com/parse-server",
        logo: OpenCollectiveLogo,
      },
      {
        name: "Parse Community",
        url: "https://community.parseplatform.org/",
        logo: ParseLogo,
      },
      {
        name: "FabricJS Patreon",
        url: "https://www.patreon.com/fabricJS/posts",
        logo: PatreonLogo,
      },
      {
        name: "FabricJS",
        url: "https://github.com/fabricjs/fabric.js",
        logo: GitHubLogo,
      },
      {
        name: "Parse Server",
        url: "https://github.com/parse-community/parse-server",
        logo: GitHubLogo,
      },
      {
        name: "Parse Dashboard",
        url: "https://github.com/parse-community/parse-dashboard",
        logo: GitHubLogo,
      },
      {
        name: "Parse Platform",
        url: "https://hub.docker.com/u/parseplatform",
        logo: DockerHubLogo,
      },
    ],
  },

  /**
   * Right Column
   */
  {
    title: "Project Management",
    displayOnSide: "right",
    apps: [
      {
        name: "Dev Board",
        url: "https://onoranzefunebricloud.atlassian.net/jira/software/c/projects/DV/boards/4",
        logo: JiraLogo,
      },
      {
        name: "Fluida",
        url: "https://app.fluida.io",
        logo: FluidaLogo,
      },
      {
        name: "Google Suite",
        url: "https://gsuite.google.com/dashboard",
        logo: GSuiteLogo,
      },
      {
        name: "Notion",
        url: "https://www.notion.so/onoranzefunebricloud",
        logo: NotionLogo,
      },
      {
        name: "Loom",
        url: "https://www.loom.com/looms/videos",
        logo: LoomLogo,
      },
    ],
  },
  {
    title: "Customers & Subscriptions",
    displayOnSide: "right",
    apps: [
      {
        name: "Contatti",
        url: "https://app-eu1.hubspot.com/contacts/25262823/objects/0-1/views/all/list",
        logo: HubspotLogo,
      },
      {
        name: "Aziende",
        url: "https://app-eu1.hubspot.com/contacts/25262823/objects/0-1/views/all/list",
        logo: HubspotLogo,
      },
      {
        name: "Chat",
        url: "https://app-eu1.hubspot.com/live-messages/25262823",
        logo: HubspotLogo,
      },
      {
        name: "Offerte",
        url: "https://app-eu1.hubspot.com/contacts/25262823/objects/0-3/views/all/list",
        logo: HubspotLogo,
      },
      {
        name: "Tickets",
        url: "https://app-eu1.hubspot.com/contacts/25262823/objects/0-5/views/all/list",
        logo: HubspotLogo,
      },
      {
        name: "Tasks",
        url: "https://app-eu1.hubspot.com/tasks/25262823/view/all",
        logo: HubspotLogo,
      },
      {
        name: "Prodotti",
        url: "https://app-eu1.hubspot.com/settings/25262823/sales/products",
        logo: HubspotLogo,
      },
      {
        name: "Intercom",
        url: "https://app.intercom.com/a/inbox/zbdnkn97/inbox",
        logo: IntercomLogo,
      },
      {
        name: "Stripe",
        url: "https://dashboard.stripe.com/login",
        logo: StripeLogo,
      },
      {
        name: "Upscope",
        url: "https://upscope.com/helloscreen",
        logo: UpscopeLogo,
      },
      {
        name: "Supporto",
        url: "https://supporto.onoranzefunebricloud.com",
        logo: OfcSupportLogo,
      },
      {
        name: "Auth0",
        url: "https://manage.auth0.com/dashboard/eu/ofc-dev/",
        logo: Auth0Logo,
        env: "dev",
      },
      {
        name: "Auth0",
        url: "https://manage.auth0.com/dashboard/eu/ofc/",
        logo: Auth0Logo,
        env: "prod",
      },
    ],
  },
  {
    title: "Analytics & ADV",
    displayOnSide: "extra",
    apps: [
      {
        name: "MixPanel Dev",
        url: "https://eu.mixpanel.com/project/3436017/view/3939521/app/boards",
        env: "dev",
        logo: MixPanel,
      },
      {
        name: "MixPanel Prod",
        url: "https://eu.mixpanel.com/project/3436013/view/3939513/app/home",
        env: "prod",
        logo: MixPanel,
      },
      {
        name: "Zoho Analytics",
        url: "https://analytics.zoho.eu/ZDBHome.cc?home=true",
        logo: ZohoAnalytics,
      },
      {
        name: "FB Business Manager",
        url: "https://business.facebook.com/adsmanager/manage/campaigns?act=380068022458345&business_id=380066085791872&global_scope_id=380066085791872&tool=MANAGE_ADS&nav_source=flyout_menu&nav_id=2891320074&date=2020-11-14_2020-11-15%2Ctoday",
        logo: FacebookLogo,
      },
      {
        name: "FB Business Suite",
        url: "https://business.facebook.com/latest/home?asset_id=1892657624281638&business_id=380066085791872&nav_ref=bm_home_redirect",
        logo: FacebookLogo,
      },
      {
        name: "G Search Console",
        url: "https://search.google.com/search-console?hl=it&resource_id=sc-domain:onoranzefunebricloud.com",
        logo: GoogleSearchConsoleLogo,
      },
      {
        name: "Google Analytics",
        url: "https://analytics.google.com/analytics/web/#/",
        logo: GoogleAnalyticsLogo,
      },
      {
        name: "Google Data Studio",
        url: "https://datastudio.google.com/u/0/reporting/2da07086-b67e-4a70-be84-eca93e43423d/page/4VDGB",
        logo: DataStudioLogo,
      },
      {
        name: "Google Tag Manager",
        url: "https://tagmanager.google.com/#/container/accounts/6001672973/containers/31994141/workspaces/4/tags",
        logo: GoogleTagManagerLogo,
      },
      {
        name: "Google Adwords",
        url: "https://ads.google.com/aw/overview?ocid=285738418&euid=303155474&__u=2186567426&uscid=285738418&__c=6725406882&authuser=0&subid=ALL-it-et-g-aw-c-home-awhp_xin1_signin!o2",
        logo: GoogleAdwordsLogo,
      },
      {
        name: "Google Trends",
        url: "https://trends.google.it/trends/?geo=IT",
        logo: GoogleTrendsLogo,
      },
      {
        name: "Google Postmasters",
        url: "https://postmaster.google.com/",
        logo: GooglePostmastersLogo,
      },
      {
        name: "Campaign Manager",
        url: "https://www.linkedin.com/campaignmanager/accounts/506849069/campaign-groups",
        logo: LinkedInLogo,
      },
    ],
  },
  {
    title: "Socials",
    displayOnSide: "extra",
    apps: [
      {
        name: "Facebook",
        url: "https://www.facebook.com/onoranzefunebricloud/",
        logo: FacebookLogo,
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/onoranzefunebricloud/",
        logo: InstagramLogo,
      },
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/company/onoranzefunebricloud/",
        logo: LinkedInLogo,
      },
      {
        name: "YouTube",
        url: "https://www.youtube.com/channel/UCAft4XAHnb_DIavWF8s2pbw",
        logo: YouTubeLogo,
      },
    ],
  },
  {
    title: "Partners",
    displayOnSide: "extra",
    apps: [
      {
        name: "Annunci Funebri",
        url: "https://www.annuncifunebri.it/",
        logo: AnnunciFunebriLogo,
      },
      {
        name: "BL",
        url: "https://www.blsrl.net/",
        logo: BlSrlLogo,
      },
    ],
  },
  {
    title: "Tools",
    displayOnSide: "extra",
    apps: [
      {
        name: "Mail Tester",
        url: "https://www.mail-tester.com/",
        logo: MailTesterLogo,
      },
    ],
  },
  {
    title: "Content & Design",
    displayOnSide: "right",
    apps: [
      {
        name: "Webflow",
        url: "https://webflow.com/dashboard?workspace=ofc-srl",
        logo: WebflowLogo,
      },
      {
        name: "Sketch OFC Team",
        url: "https://www.sketch.com/team/WKQgg/shares",
        logo: SketchLogo,
      },
      {
        name: "Unsplash",
        url: "https://unsplash.com/",
        logo: UnsplashLogo,
      },
      {
        name: "Canva",
        url: "https://www.canva.com/",
        logo: CanvaLogo,
      },
      {
        name: "Envato",
        url: "https://elements.envato.com/",
        logo: EnvatoLogo,
      },
      {
        name: "Flat Icon",
        url: "https://www.flaticon.com/",
        logo: FlatIconLogo,
      },
      {
        name: "Tailwind UI",
        url: "https://tailwindui.com/",
        logo: TailwindLogo,
      },
    ],
  },
  {
    title: "Services",
    displayOnSide: "right",
    apps: [
      {
        name: "Iubenda",
        url: "https://www.iubenda.com/it/dashboard",
        logo: IubendaLogo,
      },
      {
        name: "Lyber",
        url: "https://my.lyber.it/dashboard",
        logo: LyberLogo,
      },
      {
        name: "Aruba Partner",
        url: "https://partner.arubapec.it/arubapec/login",
        logo: ArubaPartnerLogo,
      },
      {
        name: "Fattura Elettronica",
        url: "https://fatturazioneelettronica.aruba.it/GUI/FepaApp/start.ashx#login",
        logo: ArubaFatturaElettronicaLogo,
      },
    ],
  },
  {
    title: "Startup Perks",
    displayOnSide: "right",
    apps: [
      {
        name: "GAN",
        url: "https://insight.gan.co/perks",
        logo: GanLogo,
      },
      {
        name: "F6S",
        url: "https://www.f6s.com/deals",
        logo: F6sLogo,
      },
    ],
  },
  {
    title: "Other",
    displayOnSide: "right",
    apps: [
      {
        name: "StackShare",
        url: "https://stackshare.io/onoranzefunebricloud",
        logo: StackShareLogo,
      },
    ],
  },
];

export const sidebarItems = [
  {
    title: "Launcher",
    url: "/",
    icon: <AppstoreOutlined />,
  },
  {
    title: "Clienti",
    url: "/clients",
    icon: <UserOutlined />,
  },
  {
    title: "Eterno",
    icon: <IeOutlined />,
    items: [
      {
        title: "Documenti",
        url: "/documents",
      },
      {
        title: "Doc Scanner",
        url: "/doc-scanner",
      },
    ],
  },
  {
    title: "Giotto",
    icon: <GoogleOutlined />,
    items: [
      {
        title: "Immagini",
        url: "/giotto/editor_images_default",
      },
      {
        title: "Sfondi",
        url: "/giotto/editor_backgrounds_default",
      },
      {
        title: "Simboli",
        url: "/giotto/editor_symbols_default",
      },
      {
        title: "Progetti dei Clienti",
        url: "/giotto/client-projects",
      },
      {
        title: "RemoveBG",
        url: "/giotto/removebg",
      },
      {
        title: "Migliora Foto",
        url: "/giotto/image-enhancement",
      },
      {
        title: "Pennello Magico",
        url: "/giotto/magic-brush",
      },
      {
        title: "Cambia Vestiti",
        url: "/giotto/change-clothes",
      },
      {
        title: "Sorriso",
        url: "/giotto/smile",
      },
      {
        title: "Colora B/W",
        url: "/giotto/colorize-bw",
      },
      {
        title: "Annunci Funebri",
        url: "/giotto/annunci-funebri",
      },
    ],
  },
  {
    title: "Pagamenti",
    icon: <DollarCircleOutlined />,
    items: [
      {
        title: "Nuovo Pagamento",
        url: "/sales/create-payment-page",
      },
      {
        title: "Preventivatore",
        url: "/sales/create-quote",
      },
      {
        title: "Crea contatto",
        url: "/sales/create-contact",
      },
    ],
  },
  {
    title: "Data & Analytics",
    icon: <LineChartOutlined />,
    items: [
      {
        title: "Mappa Trattative",
        url: "/deals-map",
      },
      {
        title: "Mappa Agenzie",
        url: "/mappa",
      },
      {
        title: "Usage",
        url: "/analytics/usage",
      },
    ],
  },
  {
    title: "Builds",
    url: "/ci",
    icon: <BuildOutlined />,
  },
  {
    title: "Servizi",
    icon: <ApartmentOutlined />,
    url: "/servizi",
  },
  {
    title: "Strumenti",
    icon: <ToolOutlined />,
    items: [
      {
        title: "Broadcast Notifiche",
        url: "/send-notifications",
      },
      {
        title: "Timeline Eventi",
        url: "/system-events",
      },
      {
        title: "Esporta Tabelle JSON",
        url: "/db/backup/download",
      },
      {
        title: "Crea Backup",
        url: "/db/backup/new",
      },
      {
        title: "Ripristina Backup",
        url: "/db/backup/restore",
      },
    ],
  },  
  {
    title: "Configurazioni",
    icon: <SettingOutlined />,
    items: [
      {
        title: "Features",
        url: "/settings/features",
      },
      {
        title: "Moduli",
        url: "/settings/modules",
      },
    ],
  },
];

export const orgFilters = [
  {
    title: "Clienti Attivi",
    filterName: "activeCustomers",
  },
  {
    title: "Nuovi Clienti (45d)",
    filterName: "recentCustomers",
  },
  {
    title: "Eterno Scadenze Imminenti",
    filterName: "eternoExpireSoon",
  },
  {
    title: "Eterno Scadute Recenti",
    filterName: "eternoExpiredRecently",
  },
  {
    title: "Giotto Scadenze Imminenti",
    filterName: "giottoExpireSoon",
  },
  {
    title: "Giotto Scadute Recenti",
    filterName: "giottoExpiredRecently",
  },
  {
    title: "Fatturazione Elettronica Attiva",
    filterName: "fatturazioneElettronicaAttiva",
  },
  {
    title: "Annunci Funebri Attivi",
    filterName: "annunciFunebriAttivi",
  },
];
