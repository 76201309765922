import React, { useCallback, useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Collapse,
  Switch,
  message,
  Spin,
  Tag,
  Button,
} from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { ReloadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const mapOfServices = {
  "ecs-parse-dashboard-dev": "https://parse-dashboard.dev-onoranzefunebricloud.com/",
  "ecs-parse-dashboard-prod": "https://parse-dashboard.onoranzefunebricloud.com/",
  "ecs-grafana-prod": "https://grafana.onoranzefunebricloud.com/",
  "ecs-grafana-dev": "https://grafana.dev-onoranzefunebricloud.com/",
  "eterno-dev": "https://eterno.dev-onoranzefunebricloud.com/",
  "eterno-prod": "https://eterno.onoranzefunebricloud.com/",
};

const getServiceLinks = (clusterName, serviceName) => {
  const region = 'eu-central-1';
  const awsConsoleBase = `https://${region}.console.aws.amazon.com`;
  const isEterno = serviceName.startsWith('eterno');
  const cleanedServiceName = isEterno ? serviceName : serviceName.replace(/-dev|-prod/g, '');
  const suffix = serviceName.includes('-prod') ? '$252Fprod' : '$252Fdev';
  
  let url = mapOfServices[serviceName];
  
  // Handle preview environment URLs for eterno services
  if (isEterno && !['eterno-dev', 'eterno-prod'].includes(serviceName)) {
    url = `https://${serviceName}.onoranzefunebricloud.com`;
  }
  
  return {
    ecs: `${awsConsoleBase}/ecs/v2/clusters/${clusterName}/services/${serviceName}/health?region=${region}`,
    cloudwatch: `${awsConsoleBase}/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/$252Faws$252Fecs$252F${clusterName}$252F${cleanedServiceName}${suffix}`,
    url,
  };
};

const Servizi = () => {
  const [loading, setLoading] = useState(false);
  const [clusters, setClusters] = useState({});
  const [updatingServices, setUpdatingServices] = useState(new Set());
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();
  const [countdown, setCountdown] = useState(29);

  const fetchData = useCallback(
    async (from) => {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/services`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        return responseData;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently]
  );

  const toggleService = async (serviceName, desiredCount, clusterName) => {
    try {
      // Immediately add service to updating set
      setUpdatingServices((prev) => new Set(prev).add(serviceName));

      const token = await getTokenSilently();
      const newDesiredCount = desiredCount === 0 ? 1 : 0;

      await fetch(
        `${
          config.baseUrl[isProd ? "prod" : "dev"]
        }/services/${clusterName}/${serviceName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ desiredCount: newDesiredCount }),
        }
      );

      // Refresh data after toggle
      const { data } = await fetchData();
      const groupedData = groupPreviewEnvironments(data);
      setClusters(groupedData);
      message.success(
        `Service ${serviceName} ${
          newDesiredCount === 0 ? "stopped" : "started"
        }`
      );
    } catch (error) {
      console.error(error);
      message.error("Failed to toggle service");
    } finally {
      // Remove service from updating set
      setUpdatingServices((prev) => {
        const newSet = new Set(prev);
        newSet.delete(serviceName);
        return newSet;
      });
    }
  };

  // Function to group preview environments
  const groupPreviewEnvironments = (clusters) => {
    const groupedClusters = {};

    Object.entries(clusters).forEach(([clusterName, services]) => {
      // Initialize arrays for regular services and preview environments
      const regularServices = [];
      const previewEnvironments = [];

      services.forEach((service) => {
        // Add clusterName to each service object
        const serviceWithCluster = { ...service, clusterName };
        
        if (
          service.serviceName.startsWith("eterno-") &&
          !["eterno-dev", "eterno-prod"].includes(service.serviceName)
        ) {
          previewEnvironments.push(serviceWithCluster);
        } else {
          regularServices.push(serviceWithCluster);
        }
      });

      // Add regular services to the cluster
      if (regularServices.length > 0) {
        groupedClusters[clusterName] = regularServices;
      }

      // Add preview environments as a separate group if any exist
      if (previewEnvironments.length > 0) {
        const serviceName = previewEnvironments[0].serviceName.split("-")[0];
        const formattedServiceName =
          serviceName.charAt(0).toUpperCase() + serviceName.slice(1);
        groupedClusters[`${formattedServiceName} - Preview Environments`] =
          previewEnvironments;
      }
    });

    return groupedClusters;
  };

  const isServiceToggleable = (serviceName) => {
    const allowedServices = isProd
      ? ["ecs-parse-dashboard-prod", "ecs-grafana-prod"]
      : ["ecs-parse-dashboard-dev", "ecs-grafana-dev"];

    return (
      (serviceName.startsWith("eterno-") &&
        !["eterno-dev", "eterno-prod"].includes(serviceName)) ||
      allowedServices.includes(serviceName)
    );
  };

  const handleManualRefresh = async () => {
    setLoading(true);
    const { data } = await fetchData();
    const groupedData = groupPreviewEnvironments(data);
    setClusters(groupedData);
    setLoading(false);
    setCountdown(29); // Reset countdown after manual refresh
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      setLoading(true);
      const { data } = await fetchData();
      const groupedData = groupPreviewEnvironments(data);
      setClusters(groupedData);
      setLoading(false);
      setCountdown(29); // Reset countdown after fetch
    };

    // Initial fetch
    fetchAndSetData();

    // Set up interval for auto-refresh
    const refreshInterval = setInterval(fetchAndSetData, 30000);
    
    // Set up countdown interval
    const countdownInterval = setInterval(() => {
      setCountdown(prev => prev > 0 ? prev - 1 : 29);
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(refreshInterval);
      clearInterval(countdownInterval);
    };
  }, [fetchData]);

  return (
    <div style={{ margin: "16px 16px" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Title level={3} style={{ margin: 0 }}>
          Servizi
        </Title>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Tag color="blue">
            Auto-Refresh in {countdown}s
          </Tag>
          <Button 
             type="primary"
             size="small"
            icon={<ReloadOutlined spin={loading} />} 
            onClick={loading ? undefined : handleManualRefresh}
            style={{ 
              opacity: loading ? 0.5 : 1,
            }}
            disabled={loading}
          >Refresh now
          </Button>
        </div>
      </div>
      <Divider />
      {loading && <LinearProgress />}

      {loading === false &&
        Object.entries(clusters).map(([clusterName, services]) => (
          <div key={clusterName} style={{ marginBottom: "32px" }}>
            <Collapse defaultActiveKey={[clusterName]}>
              <Collapse.Panel
                header={`Cluster: ${clusterName}`}
                key={clusterName}
              >
                <Row gutter={[16, 16]}>
                  {services.map((service) => {
                    const isToggleable = isServiceToggleable(
                      service.serviceName
                    );
                    const isStable =
                      service.runningCount === service.desiredCount;
                    const isUpdating =
                      !isStable || updatingServices.has(service.serviceName);

                    return (
                      <Col
                        key={service.serviceName}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                      >
                        <Spin spinning={isUpdating}>
                          <Card
                            loading={loading}
                            hoverable
                            style={{
                              borderRadius: "8px",
                              opacity: service.runningCount === 0 ? 0.4 : 1,
                              backgroundColor: isUpdating
                                ? "#fff3e0"
                                : service.runningCount === 0
                                ? "#ffebee"
                                : "#e8f5e9",
                              border: isUpdating
                                ? "1px solid #ffe0b2"
                                : service.runningCount === 0
                                ? "1px solid #ffcdd2"
                                : "1px solid #c8e6c9",
                              boxShadow: isUpdating
                                ? "0 0 4px rgba(255,165,0,0.2)"
                                : service.runningCount === 0
                                ? "0 0 4px rgba(255,0,0,0.2)"
                                : "0 0 4px rgba(0,255,0,0.1)",
                            }}
                            title={service.serviceName.replace("ecs-", "")}
                            extra={
                              isToggleable && (
                                <Switch
                                  checked={service.desiredCount > 0}
                                  onChange={() =>
                                    toggleService(
                                      service.serviceName,
                                      service.desiredCount,
                                      service.clusterName
                                    )
                                  }
                                  disabled={isUpdating}
                                />
                              )
                            }
                          >
                            <div style={{ textAlign: "center" }}>
                              <Text
                                strong
                                style={{
                                  fontSize: "16px",
                                  color: isUpdating ? "#ff9800" : "inherit",
                                }}
                              >
                                {service.runningCount} / {service.desiredCount}
                              </Text>
                              <Text
                                style={{ display: "block", marginTop: "8px" }}
                              >
                                {isUpdating ? "Updating..." : "Tasks Running"}
                              </Text>
                            </div>
                            <Divider style={{ margin: "12px 0" }} />
                            <div style={{ display: "flex", justifyContent: "space-around", fontSize: "12px" }}>
                              {(() => {
                                const links = getServiceLinks(clusterName, service.serviceName);
                                console.log(links);
                                return (
                                  <>
                                    <a href={links.ecs} target="_blank" rel="noreferrer">ECS</a>
                                    <a href={links.cloudwatch} target="_blank" rel="noreferrer">CloudWatch</a>
                                    {links.url && <a href={links.url} target="_blank" rel="noreferrer">Public URL</a>}
                                  </>
                                );
                              })()}
                            </div>
                          </Card>
                        </Spin>
                      </Col>
                    );
                  })}
                </Row>
              </Collapse.Panel>
            </Collapse>
          </div>
        ))}
    </div>
  );
};

export default Servizi;
