import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Tag,
  Divider,
  Badge,
  Popover,
} from "antd";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { formatDistance } from "date-fns";
import { it } from "date-fns/locale";
import ModuleEditModal from "./ModuleEditModal";

const { Title } = Typography;

const query = `
  query fetchModules($where: ModuleWhereInput) {
    modules(where: $where, first: 10000) {
      edges {
        node {
          objectId
          name
          updatedAt
          module_category_pointer {
            objectId
            name
            order
            color
          }
          included_service_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          related_service_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          target_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          power_by
          power_by_link
          una_tantum
          quantity
          included_quantity
          charge_by_quantity
          main_service
          hours_of_training
          price
          price_for_quantity
          isService
          sku
          order
          is_active
        }
      }
    }
  }
`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

const Modules = () => {
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editingModule, setEditingModule] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const fetchData = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query,
          variables: {
            where: {},
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const modulesData = response.data.map((node) => ({
        ...node,
        key: node.objectId,
        category: node.module_category_pointer?.name || '',
        categoryColor: node.module_category_pointer?.color,
        includedServices: node.included_service_relation?.edges.map(e => e.node.name) || [],
        relatedServices: node.related_service_relation?.edges.map(e => e.node.name) || [],
        targets: node.target_relation?.edges.map(e => e.node.name) || [],
      })).sort((a, b) => (a.order || 0) - (b.order || 0));

      setModules(modulesData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching modules:", error);
      setLoading(false);
    }
  }, [getTokenSilently, graphqlUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit = (record) => {
    setEditingModule(record);
    setIsModalVisible(true);
  };

  const handleNew = () => {
    setEditingModule(null);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEditingModule(null);
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setEditingModule(null);
    setIsModalVisible(false);
    fetchData();
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "5%",
      sorter: (a, b) => (a.order || 0) - (b.order || 0),
      render: (order) => order || '-',
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Stato",
      dataIndex: "is_active",
      key: "is_active",
      width: "8%",
      filters: [
        { text: "Attivo", value: true },
        { text: "Inattivo", value: false },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (isActive) => (
        <Tag color={isActive ? "success" : "error"}>
          {isActive ? "Attivo" : "Inattivo"}
        </Tag>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: "10%",
      ...getColumnSearchProps("sku"),
      render: (sku) => sku ? (
        <Tag color="default" style={{ background: "#fafafa" }}>{sku}</Tag>
      ) : '-',
    },
    {
      title: "Categoria",
      dataIndex: "category",
      key: "category",
      width: "15%",
      filters: [...new Set(modules.map(m => m.category))].map(cat => ({
        text: cat,
        value: cat,
      })),
      onFilter: (value, record) => record.category === value,
      render: (text, record) => (
        <Tag color={record.categoryColor || 'default'}>{text}</Tag>
      ),
    },
    {
      title: "Prezzo",
      dataIndex: "price",
      key: "price",
      width: "10%",
      sorter: (a, b) => a.price - b.price,
      render: (price) => price ? `${price.toFixed(2)} €` : '-',
    },
    {
      title: "Minuti di Formazione",
      dataIndex: "hours_of_training",
      key: "hours_of_training",
      width: "10%",
      sorter: (a, b) => (a.hours_of_training || 0) - (b.hours_of_training || 0),
      render: (hours) => hours ? `${hours} min` : '-',
    },
    {
      title: "Tipologia",
      dataIndex: "isService",
      key: "isService",
      width: "10%",
      filters: [
        { text: "Service", value: true },
        { text: "Product", value: false },
      ],
      onFilter: (value, record) => record.isService === value,
      render: (isService) => (
        <Tag color={isService ? "blue" : "green"}>
          {isService ? "Service" : "Product"}
        </Tag>
      ),
    },
    {
      title: "Una Tantum",
      dataIndex: "una_tantum",
      key: "una_tantum",
      width: "10%",
      filters: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.una_tantum === value,
      render: (unaTantum) => (
        <Tag color={unaTantum ? "success" : "default"}>
          {unaTantum ? "Si" : "No"}
        </Tag>
      ),
    },
    {
      title: "Moduli Inclusi",
      dataIndex: "includedServices",
      key: "includedServices",
      width: "15%",
      filters: [...new Set(modules.flatMap(m => m.includedServices))].map(service => ({
        text: service,
        value: service,
      })),
      onFilter: (value, record) => record.includedServices.includes(value),
      render: (services) => services.length ? (
        <Popover
          content={
            <Space wrap>
              {[...services].sort((a, b) => a.localeCompare(b)).map((service, index) => (
                <Tag key={index}>{service}</Tag>
              ))}
            </Space>
          }
          trigger="hover"
        >
          <Tag style={{ cursor: 'pointer' }}>{services.length} moduli</Tag>
        </Popover>
      ) : '-',
    },
    {
      title: "Targets",
      dataIndex: "targets",
      key: "targets", 
      width: "15%",
      filters: [...new Set(modules.flatMap(m => m.targets))].map(target => ({
        text: target,
        value: target,
      })),
      onFilter: (value, record) => record.targets.includes(value),
      render: (targets) => targets.length ? (
        <Popover
          content={
            <Space wrap>
              {[...targets].sort((a, b) => a.localeCompare(b)).map((target, index) => (
                <Tag key={index} color="cyan">{target}</Tag>
              ))}
            </Space>
          }
          trigger="hover"
        >
          <Tag style={{ cursor: 'pointer' }}>{targets.length} targets</Tag>
        </Popover>
      ) : '-',
    },
    {
      title: "Aquistabile Singolarmente",
      dataIndex: "main_service",
      key: "main_service",
      width: "10%",
      filters: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.main_service === value,
      render: (mainService) => (
        <Tag color={mainService ? "success" : "default"}>
          {mainService ? "Si" : "No"}
        </Tag>
      ),
    },
    {
      title: "Powered By",
      dataIndex: "power_by",
      key: "power_by",
      width: "10%",
      render: (powerBy, record) => powerBy ? (
        <a 
          href={record.power_by_link} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {powerBy}
        </a>
      ) : '-',
    },
    {
      title: "Ultimo Aggiornamento",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      sorter: (a, b) => a.updatedAt - b.updatedAt,
      render: (updatedAt) => updatedAt ? (
        <Tag style={{ background: "#fafafa" }}>
          {formatDistance(new Date(updatedAt), new Date(), {
            locale: it,
          })}
        </Tag>
      ) : '-',
    },
    {
      title: "Azioni",
      key: "actions",
      width: "5%",
      render: (_, record) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        />
      ),
    },
  ];

  return (
    <div style={{ margin: "16px 16px" }}>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Configurazioni</Breadcrumb.Item>
        <Breadcrumb.Item>Moduli</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "40px" }}>
        <Title level={3}>
          <Space>
            Moduli
            <Badge overflowCount={5000} count={modules.length} />
          </Space>
        </Title>
        <Button type="primary" onClick={handleNew}>
          Nuovo Modulo
        </Button>
      </div>
      <Table
        size="small"
        dataSource={modules}
        columns={columns}
        loading={loading}
        rowKey="key"
        pagination={false}
      />
      <ModuleEditModal
        visible={isModalVisible}
        module={editingModule}
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />
    </div>
  );
};

export default Modules; 